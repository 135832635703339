<template>
    <section>
        <tabs :tabs="rutas" class="border-b-gris pt-2" />
        <div class="p-2 overflow-auto custom-scroll" style="height: calc(100vh - 182px);">
            <router-view />
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
let that = this
export default {
    data(){
        return {

            rutas: [
                { titulo: 'Estadísticas', ruta: 'admin.tesoreria.creditos.dashboard', can: 'tab2_tesoreria_creditos_dashboard' },
                { titulo: '', ruta: 'admin.tesoreria.creditos.tenderos', can: 'tab2_tesoreria_creditos_leecheros' },
                { titulo: 'Pedidos por cobrar', ruta: 'admin.tesoreria.creditos.pedidos-cobrar', can: 'tab2_tesoreria_creditos_leecheros' },
                // { titulo: 'Cuentas por cobrar', ruta: 'admin.tesoreria.creditos.cuotas', can: 'tab2_tesoreria_creditos_cuotas' },
                //{ titulo: 'Recibo de dinero', ruta: 'admin.tesoreria.creditos.dinero-recibido', can: 'tab2_tesoreria_creditos_dinero_recibido' },
            ],
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch:{
        id_cedis(val){
            this.listarDatosGraficas()
        },
        id_moneda(val){
            this.listarDatosGraficas()
        }
    },
    mounted(){
        this.rutas[1].titulo = `${this.$config.cliente} con deudas`;
    },
    methods:{
        listarDatosGraficas(){
            this.$store.commit('almacen/credito/set_grafica_dos_meses', [])
            if(this.$route.name==='admin.tesoreria.creditos.dashboard' || this.$route.name==='admin.tesoreria.creditos.cuotas'){
                const tendero = 1
                const params = {
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                    tendero
                }
                this.$store.dispatch('almacen/credito/action_listar_creditos_grafica_dos_meses', params)

            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
